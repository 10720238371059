import React from "react";
import classes from "./FAQ.module.css";
import headerClasses from "../Home/Home.module.css";

const FAQsRules = () => {
  const faqs = [
    {
      question: "How do I book a wedding?",
      answer: (
        <p>
          To book, please click{" "}
          <a href="mailto:booking@prettybirdwedding.com?subject=Wedding/Event Inquiry&body=Name: %0D%0AContact Email: %0D%0APhone number: %0D%0ADates desired: %0D%0APackage: %0D%0ANumber of Guests: %0D%0AAny Additional Items or Questions: ">
            booking@prettybirdwedding.com
          </a>{" "}
          to open a new email with a pre-filled template. Fill in your
          information as requested and let us know if you're ready to receive
          the contract via Docusign.
        </p>
      ),
    },
    {
      question: "What are the arrival and checkout times?",
      answer:
        "Check-in is at 2 PM and checkout is at 10 AM. However, we allow parties to arrive at 10 AM to start setting up. Please note, our cleaning crew will be on-site from 10 AM to 2 PM, and during this time, we may also give tours, attend to animals, and set up for weddings depending on the package you have selected. Caterers and other vendors are also permitted to arrive starting at 10 AM.",
    },
    {
      question: "What are the sizes and quantities of the available tables?",
      answer:
        "We offer over 40 round tables measuring 48 inches in diameter, which can comfortably seat 6 guests each. Additionally, we have 5 rectangular tables of various sizes, which are perfect for buffet setups.",
    },
    {
      question: "What is the WiFi password?",
      answer:
        "The WiFi password is 'Happyweddings', available on the Prettybird network.",
    },
    {
      question: "What type of sound system is recommended?",
      answer:
        "We recommend using a Bluetooth speaker compatible with your devices. Smaller speakers are advisable as the venue acoustics are quite good.",
    },
    {
      question: "How do I purchase event insurance?",
      answer: (
        <p>
          Please use{" "}
          <a
            target="_blank"
            href="https://secure.rightsignature.com/templates/1beb6406-ec26-415f-beea-35fc4a7ea519/template-signer-link/f8b80437724c556752e02ed5252659ac"
          >
            this link
          </a>
        </p>
      ),
    },
    {
      question: "Do you offer elopement packages?",
      answer: (
        <p>
          Yes, please see the Puffin package at{" "}
          <a target="_blank" href="https://prettybirdwedding.com/packages">
            https://prettybirdwedding.com/packages
          </a>
        </p>
      ),
    },
    {
      question: "How many people can spend the night?",
      answer:
        "The house sleeps 16 people comfortably, but as many people as you like can spend the night at no additional charge.",
    },
    {
      question: "Can we bring our own alcohol/food?",
      answer:
        "Yes. Please just make sure to purchase the appropriate permits, such as a banquet permit ($10).",
    },
    {
      question: "What are the checkout and cleanup procedures?",
      answer:
        "Checkout is at 10 AM. Please bag all your garbage, remove anything you brought in, and place all used towels on the floor in the laundry room.",
    },
    {
      question: "Can we bring trailers, tents, and camping gear?",
      answer: "Yes, but please no campfires or open flames.",
    },
    {
      question: "Can we add items/days to our booking later?",
      answer: "Yes. Please email us with your requests.",
    },
    {
      question: "Can we tour multiple times to help plan our event?",
      answer: "Yes. Please email us to schedule tours.",
    },
  ];

  const rules = [
    {
      rule: "Fire Restrictions",
      text: "For the safety of everyone, no open flames are allowed on the premises. This includes but is not limited to: fireworks, campfires, bonfires, candles, flambe cooking, sterno cans, and citronella fuel cans.",
    },
    {
      rule: "Fire Safety Equipment",
      text: "Please do not move or tamper with fire extinguishers and fire blankets; they must remain in their designated locations.",
    },
    {
      rule: "Dog Policy",
      text: "Dogs are welcome, but for the safety and comfort of all guests, the following breeds must be leashed, secured, or under direct supervision at all times: Pit Bull, Gull Dong, Bull Terrier, Caucasian Ovcharka, German Shepherd, Rottweiler, Husky, Doberman Pinscher, Chow Chow, Wolf Hybrid, Akita, Mastiff, Malamute, Great Dane, Cane Corso, Staffordshire Terrier, Perro de Presa, Dalmatian, Boxer, and Presa Canario. Service animals are exempt from these restrictions.",
    },
    {
      rule: "Interaction with Animals",
      text: "For your safety and the well-being of local wildlife, please do not touch, approach, or interact with any animals on the property or any wild animals.",
    },
    {
      rule: "Noise",
      text: "No concerts or raves are allowed. Snohomish County requires us to keep noise levels down after 10pm, so please turn down any music at that time.",
    },
    {
      rule: "Glitter",
      text: "Glitter, confetti, and rice is not allowed on the property. Flower petals are okay.",
    },
  ];

  return (
    <div>
      <div className={classes.headerBackdrop}>
        <div className={headerClasses.header}>
          <h1>FAQ & Rules</h1>
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.faqSection}>
          <h2>Frequently Asked Questions</h2>
          {faqs.map((faq, index) => (
            <div className={classes.faqItem} key={index}>
              <h3>{faq.question}</h3>
              <p>{faq.answer}</p>
            </div>
          ))}
        </div>
        <div className={classes.rulesSection}>
          <h2>Event Rules</h2>
          <ul>
            {rules.map((item, index) => (
              <li key={index}>
                <b style={{ fontSize: "17px" }}>{item.rule}:</b> {item.text}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FAQsRules;
